import React from 'react'
import { ProductPlate } from '@/components/ProductPlate'
import Link from '@/next/link'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { IconWrapper } from '@/components/IconWrapper'
import { LoanIcon } from '@platform-ui/icons/LoanIcon'
import { ProductShimmer, ProductListStyled } from './styled'
import { ClientProductsModel } from '@/api/products/clientProducts'

interface ClosedCreditLoansProps {
  isLoading: boolean
  closedCreditLoans: ClientProductsModel.CreditLoan[]
}

export const ClosedCreditLoans = ({ isLoading, closedCreditLoans }: ClosedCreditLoansProps) => {
  const context = useAppContext<AppContext>()
  if (isLoading) {
    return <ProductShimmer />
  }

  return (
    <>
      {!!closedCreditLoans.length && (
        <ProductListStyled title="Кредиты">
          {closedCreditLoans.map((product) => (
            <Link href={context.routes.credit(product.id)} passHref>
              <ProductPlate
                name={product.productName}
                description={product.description}
                icon={
                  <IconWrapper backgroundColor="products/loanNew" textColor="text/tertiary">
                    <LoanIcon color="icons/shape" size="s12" />
                  </IconWrapper>
                }
                amount={product.loanBalance}
                currencyType={product.currencyType}
                bgColor={product.bgColor}
                bgColorTheme={product.bgColorTheme}
              />
            </Link>
          ))}
        </ProductListStyled>
      )}
    </>
  )
}
