import { ProductList } from '@/components/ProductList'
import styled from '@emotion/styled'
import { Shimmer } from '@platform-ui/components/Shimmer'
import { ProductError } from '@/components/ProductError'

export const ProductShimmer = styled(Shimmer)(() => ({
  margin: '12px 0',
  height: '88px',
  width: '100%',
  borderRadius: '20px',
  ':first-of-type': {
    marginTop: '0',
  },
}))

export const ProductListStyled = styled(ProductList)(({ theme }) => ({
  marginTop: 24,
  h3: {
    fontSize: 20,
  },

  [`${theme.media.md}`]: {
    marginTop: 40,
  },
}))

export const ProductErrorStyled = styled(ProductError)(({ theme }) => ({
  marginBottom: 12,

  [`${theme.media.md}`]: {
    marginBottom: 12,
  },
}))
