import React from 'react'
import { ProductPlate } from '@/components/ProductPlate'
import { IconWrapper } from '@/components/IconWrapper'
import { PercentIcon } from '@platform-ui/icons/PercentIcon'
import { DocsIcon } from '@platform-ui/icons/DocsIcon'
import { ProductShimmer, ProductListStyled } from './styled'
import { ProductsModel } from '@/api/products/products'

function format(value, pattern) {
  let i = 0
  const v = value.toString()
  return pattern.replace(/#/g, (_) => v[i++])
}

interface ClosedProductsProps {
  isLoading: boolean
  closedCards: (ProductsModel.DebitCard | ProductsModel.CreditCard)[]
  closedDeposits: ProductsModel.Deposit[]
  closedAccounts: ProductsModel.Account[]
}

export const ClosedProducts = ({
  isLoading,
  closedCards,
  closedDeposits,
  closedAccounts,
}: ClosedProductsProps) => {
  if (isLoading) {
    return <ProductShimmer />
  }

  return (
    <>
      {!!closedCards.length && (
        <ProductListStyled title="Кошелёк">
          {closedCards.map((product) => (
            <ProductPlate
              name={product.productName}
              description={product.description}
              amount={product.availableBalance}
              currencyType={product.currencyType}
              bgColor={product.bgColor}
              bgColorTheme={product.bgColorTheme}
              lastFourCardDigits={product.lastFourCardDigits}
              paymentSystem={product.paymentSystem}
            />
          ))}
        </ProductListStyled>
      )}
      {!!closedDeposits.length && (
        <ProductListStyled title="Накопления">
          {closedDeposits.map((product) => (
            <ProductPlate
              name={product.productName}
              description={product.description}
              amount={product.runningBalance}
              currencyType={product.currencyType}
              bgColor={product.bgColor}
              bgColorTheme={product.bgColorTheme}
              icon={
                <IconWrapper backgroundColor="products/deposit" textColor="text/tertiary">
                  <PercentIcon color="icons/shape" size="s12" />
                </IconWrapper>
              }
            />
          ))}
        </ProductListStyled>
      )}

      {!!closedAccounts.length && (
        <ProductListStyled title="Счета">
          {closedAccounts.map((product) => (
            <ProductPlate
              name={product.productName}
              description={format(product.accountNumber, '##### ### # #### #######')}
              amount={product.runningBalance}
              currencyType={product.currencyType}
              bgColor={product.bgColor}
              bgColorTheme={product.bgColorTheme}
              icon={
                <IconWrapper backgroundColor="icons/secondary" textColor="text/tertiary">
                  <DocsIcon color="icons/shape" size="s12" />
                </IconWrapper>
              }
            />
          ))}
        </ProductListStyled>
      )}
    </>
  )
}
