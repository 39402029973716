import React from 'react'
import { observer } from 'mobx-react-lite'
import { useClosedProducts } from '@/hooks/useClosedProducts'
import { ApiStatus } from '@/core'
import { ProductErrorStyled } from './styled'
import { ClientProductsModel } from '@/api/products/clientProducts'
import { ProductsModel } from '@/api/products/products'
import { SystemError } from '@/models/SystemError'
import { ClosedProducts } from './ClosedProducts'
import { ClosedCreditLoans } from './ClosedCreditLoans'

interface ClosedProductListsProps {
  closedCreditLoans: ClientProductsModel.CreditLoan[]
  closedCards: (ProductsModel.DebitCard | ProductsModel.CreditCard)[]
  closedDeposits: ProductsModel.Deposit[]
  closedAccounts: ProductsModel.Account[]
  statusProducts: ApiStatus
  statusCreditLoans: ApiStatus
  error: SystemError<'NETWORK' | 'PARTIAL_RESPONSE' | 'IS_EMPTY_PRODUCTS'>
  retry: () => void
}

export const ClosedProductLists = observer(() => {
  const {
    closedCreditLoans,
    closedCards,
    closedDeposits,
    closedAccounts,
    statusProducts,
    statusCreditLoans,
    error,
    retry,
  }: ClosedProductListsProps = useClosedProducts()
  const isLoadingProducts = statusProducts === ApiStatus.Pending
  const isLoadingCreditLoans = statusCreditLoans === ApiStatus.Pending

  return (
    <>
      <ClosedProducts
        isLoading={isLoadingProducts}
        closedCards={closedCards}
        closedDeposits={closedDeposits}
        closedAccounts={closedAccounts}
      />

      <ClosedCreditLoans isLoading={isLoadingCreditLoans} closedCreditLoans={closedCreditLoans} />

      {error && (
        <ProductErrorStyled
          message={error.message}
          retryClick={error.type !== 'IS_EMPTY_PRODUCTS' && retry}
        />
      )}
    </>
  )
})
